import React from "react"
import { Link } from "gatsby"
// Components
import Seo from "../components/seo"
import MainLayout from "../layouts/MainLayout"
import Section from "../components/section/Section"

export default function Changelog() {
  return (
    <MainLayout>
      <Seo
        title="Tailwind CSS - Changelog"
        ogtitle="Tailwind CSS - Changelog"
        description="Evolutionary and accessible UI components built with Tailwind"
        ogdescription="Evolutionary and accessible UI components built with Tailwind."
        author="Wind UI"
        keywords="Wind UI, Tailwind, CSS, Component, Accessible, Changelog"
        robots="index, follow"
        revisit_after="7 days"
      />
      <Section>
        <div className="container justify-center py-16 lg:py-24">
          <div className="row grid grid-cols-12">
            <h1 className="col-start-2">Changelog</h1>
          </div>
          <div className="row grid grid-cols-12">
            <div className="col col-span-8 col-start-2 flex flex-col-reverse">
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  27/7/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.0</h4>
                  <p>Initial release for Wind-UI 🎉🎉🎉.</p>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/badges/">Badges</Link> with 12
                      variations
                    </li>
                    <li>
                      Added <Link to="/components/buttons/">Buttons</Link> with
                      132 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/breadcrumbs/">Breadcrumbs</Link>{" "}
                      with 6 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/checkbox/">
                        Checkbox
                      </Link>{" "}
                      with 6 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/input-groups/">
                        Input Groups
                      </Link>{" "}
                      with 28 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/radio-groups/">
                        Radio
                      </Link>{" "}
                      with 2 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/select-menus/">
                        Select Menus
                      </Link>{" "}
                      with 12 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/text-area/">
                        Textarea
                      </Link>{" "}
                      with 16 variations
                    </li>
                    <li>
                      Added <Link to="/components/tooltips/">Tooltips</Link>{" "}
                      with 8 variations
                    </li>
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/toggle/">Toggle</Link>{" "}
                      with 12 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  28/7/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.1</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/pagination/">Pagination</Link>{" "}
                      with 20 variations
                    </li>
                    <li>
                      Added focus state on{" "}
                      <Link to="/components/form-elements/toggle/">
                        Toggles
                      </Link>{" "}
                    </li>
                    <li>
                      Added focus state on{" "}
                      <Link to="/components/form-elements/radio-groups/">
                        Radio Buttons
                      </Link>
                    </li>
                    <li>
                      Added focus state on{" "}
                      <Link to="/components/form-elements/checkbox/">
                        Checkbox
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  2/8/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.2</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/pagination/">Tabs</Link> with
                      15 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  3/8/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.3</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/tables/">Tables</Link> with 8
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  4/8/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.4</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/avatars/">Avatars</Link> with
                      171 variations
                    </li>
                    <li>
                      Updated hover & focus state on{" "}
                      <Link to="/components/buttons/">Buttons</Link>
                    </li>
                    <li>
                      Updated hover & focus state on{" "}
                      <Link to="/components/form-elements/checkbox/">
                        Checkbox
                      </Link>
                    </li>
                    <li>
                      Updated hover & focus state on{" "}
                      <Link to="/components/form-elements/radio-groups/">
                        Radio
                      </Link>
                    </li>
                    <li>
                      Updated hover & focus state on{" "}
                      <Link to="/components/form-elements/toggle/">Toggle</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  9/8/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.5</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/cards/">Cards</Link> with 13
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  31/8/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.6</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/accordions/">Accordions</Link>{" "}
                      with 6 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  1/9/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.7</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/alerts/">Alerts</Link> with 35
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  6/9/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.8</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/modals/">Modals</Link> with 5
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  8/9/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.9</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/progress-bars/">Progress Bars</Link>{" "}
                      with 28 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  13/9/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.10</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/spinners/">Spinners</Link>{" "}
                      with 25 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-wuiSlate-200">
                  3/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.11</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/dropdowns/">Dropdowns</Link>{" "}
                      with 2 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  4/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.12</h4>
                  <ul className="text-sm">
                    <li>
                      Updated contrast on Secondary{" "}
                      <Link to="/components/buttons/">Buttons</Link>{" "}
                    </li>
                    <li>
                      Added <Link to="/components/layouts/">Layouts</Link> with
                      14 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  5/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.13</h4>
                  <ul className="text-sm">
                    <li>Introduced Color Chooser</li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  7/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.14</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/navbars/">Navbars</Link> with
                      4 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  11/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.15</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/footers/">Footers</Link> with
                      9 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  20/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.16</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/testimonials/">Testimonials</Link>{" "}
                      with 22 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  27/10/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.17</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/ratings/">Ratings</Link> with
                      16 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  2/11/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.18</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/sliders/">Sliders</Link> with
                      7 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  11/11/2022
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.19</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/pricing/">Pricing Tables</Link> with
                      9 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  27/02/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.20</h4>
                  <ul className="text-sm">
                    <li>
                      Updated{" "}
                      <Link to="/components/form-elements/radio-groups/">
                        Radio Groups
                      </Link>
                      . Inputs are now triggered when clicking on the icon
                    </li>
                    <li>
                      Added <Link to="/components/feeds/">Feeds</Link> with 12
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  3/03/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.21</h4>
                  <ul className="text-sm">
                    <li>
                      Updated{" "}
                      <Link to="/components/form-elements/input-groups/">
                        Input groups
                      </Link>{" "}
                      and text areas. Now when hovering on the the labels cursor
                      has the proper behavior.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  22/03/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.22</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/lists/">Lists</Link> with 54
                      variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  01/06/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.23</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/notifications/">Notifications</Link>{" "}
                      with 24 variations
                    </li>
                    <li>
                      Updated badge position on sm{" "}
                      <Link to="/components/buttons/">Buttons</Link>
                    </li>
                    <li>
                      Removed unnecessary wrappers from{" "}
                      <Link to="/components/form-elements/input-groups/">
                        Input Groups
                      </Link>
                    </li>
                    <li>
                      Small fixes for{" "}
                      <Link to="/components/spinners/">Spinners</Link>,{" "}
                      <Link to="/components/dropdowns/">Dropdowns</Link> and{" "}
                      <Link to="/components/cards/">Cards</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  14/07/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.24</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/carousels/">Carousels</Link>{" "}
                      with 8 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  15/09/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.25</h4>
                  <ul className="text-sm">
                    <li>
                      Added <Link to="/components/features/">Features</Link>{" "}
                      with 12 variations
                    </li>
                    <li>
                      Accessibility fixes for{" "}
                      <Link to="/components/navbars/">NavBars</Link>
                    </li>
                    <li>
                      Updated autofill rules for{" "}
                      <Link to="/components/form-elements/input-groups/">
                        Input Groups
                      </Link>
                    </li>
                    <li>
                      Small fixes for{" "}
                      <Link to="/components/breadcrumbs/">Breadcrumbs</Link>,{" "}
                      <Link to="/components/accordions/">Accordions</Link> and{" "}
                      <Link to="/components/tables/">Tables</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  16/10/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.26</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/side-navigation/">
                        Side Navigation
                      </Link>{" "}
                      with 5 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  24/11/2023
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.27</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/button-groups/">Button Groups</Link>{" "}
                      with 78 variations
                    </li>
                    <li>
                      Added search bar variations in{" "}
                      <Link to="/components/form-elements/input-groups/">
                        Input Groups
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  18/01/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.28</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/button-switchers/">
                        Button Switchers
                      </Link>{" "}
                      with 18 variations
                    </li>
                    <li>Updated Tailwind to v.3.4.1</li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  05/02/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.29</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/chip-notifications/">
                        Chip Notifications
                      </Link>{" "}
                      with 16 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  16/02/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.30</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/fabs/">
                        Floating Action Buttons
                      </Link>{" "}
                      with 20 variations
                    </li>
                    <li>
                      Updated backgrounds for{" "}
                      <Link to="/components/chip-notifications/">
                        Chip Notifications
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  23/02/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.31</h4>
                  <ul className="text-sm">
                    <li>
                      Updated <Link to="/components/buttons/">Buttons</Link>,
                      added{" "}
                      <Link to="/components/buttons/#AppButtons">
                        App Buttons
                      </Link>{" "}
                      with 12 variations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  08/03/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.32</h4>
                  <ul className="text-sm">
                    <li>
                      Updated our{" "}
                      <Link to="/components/">Component Library</Link> page
                      layout
                    </li>
                    <li>
                      Created a standalone page for{" "}
                      <Link to="/components/form-elements/search-inputs/">
                        Search Inputs
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  12/03/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.33</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/datepickers/">
                        Datepickers
                      </Link>{" "}
                      with 12 variations!
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  29/03/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.34</h4>
                  <ul className="text-sm">
                    <li>
                      Added{" "}
                      <Link to="/components/form-elements/file-inputs/">
                        File Inputs
                      </Link>{" "}
                      with 30 variations!
                    </li>
                    <li>
                      Created a standalone page for{" "}
                      <Link to="/components/app-buttons/">App Buttons</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  10/05/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.35</h4>
                  <ul className="text-sm">
                    <li>
                      Added 6 new{" "}
                      <Link to="/components/form-elements/toggles/#TogglesWithIcons">
                        Toggle
                      </Link>{" "}
                      variations!
                    </li>
                    <li>
                      Added new{" "}
                      <Link to="/components/navbars/#NavBarWithIcons">
                        Navigation bar with icons
                      </Link>{" "}
                      variation!
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex gap-12">
                <h4 className="flex-2 not-prose w-20 font-normal text-slate-200">
                  06/06/2024
                </h4>
                <div className="relative w-px bg-wuiSlate-300 before:absolute before:-left-1 before:top-8 before:h-2 before:w-2 before:rounded-full before:bg-wuiSlate-100 before:ring-2 before:ring-wuiSlate-300"></div>
                <div>
                  <h4>v1.0.36</h4>
                  <ul className="text-sm">
                    <li>Updated Tailwind to v.3.4.3</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </MainLayout>
  )
}
